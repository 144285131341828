import { SpecialProductTypeService } from './../../services/special-product-type-service';
import { SpecialProductDimensionService } from './../../services/special-product-dimension-service';
import { NettingService } from 'services/netting-service';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { favnToMeters, metersToFavn } from 'lib/math';
import { AuthorizeStep } from 'authorizeStep';

@autoinject
export class SpecialProductDimension {
  @bindable private netId: number;
  @bindable private net: Models.Net;
  @bindable private locked = false;

  private nextTabIndex: number = null;
  private originalObject: Models.Net;

  specialProductType: Models.SpecialProductType;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netService: NetService,
    private specialProductDimensionService: SpecialProductDimensionService,
    private specialProductTypeService: SpecialProductTypeService,
    private router: Router,
    private toastService: ToastService,
    private nettingService: NettingService,
    private utility: Utility
  ) {}

  activate(params: { NetId: number; Id: number }) {
    this.netId = params.NetId || params.Id;
    void this.getNet(this.netId);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  // Get net and netdimension
  private async getNet(id: number) {
    try {
      const net = await this.netService.get(id + '?$expand=SpecialProductDimension');
      this.net = net;
      this.locked = net.Locked ? net.Locked : false;

      const products = await this.specialProductTypeService.getAllCached();
      this.specialProductType = products.find((x) => x.Id === this.net.SpecialProductTypeId);

      this.updateOriginalObject();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async updateSpecialProductDimension() {
    try {
      await this.specialProductDimensionService.put(
        this.net.SpecialProductDimension,
        this.net.SpecialProductDimension.Id
      );

      const netCopy = JSON.parse(JSON.stringify(this.net));
      netCopy.SpecialProductDimension = null;

      await this.netService.put(netCopy, netCopy.Id);
      this.updateOriginalObject();
      this.toastService.showSuccess('specialproduct.updated');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private updateOriginalObject() {
    if (!this.net) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.net));
  }

  private setCustomerDrawingFileId(event: any) {
    this.net.CustomerDrawingFileId = event.detail.id;
    this.updateNet();
  }

  private setProductionDrawingFileId(event: any) {
    this.net.ProductionDrawingFileId = event.detail.id;
    this.updateNet();
  }

  private removeCustomerDrawingFileId() {
    this.net.CustomerDrawingFileId = null;
    this.updateNet();
  }

  private removeProductionDrawingFileId() {
    this.net.ProductionDrawingFileId = null;
    this.updateNet();
  }

  private async updateNet() {
    const netModification = new Models.Net();

    let changed = false;

    if (this.originalObject.ProductionDrawingFileId !== this.net.ProductionDrawingFileId) {
      netModification.ProductionDrawingFileId = this.net.ProductionDrawingFileId;
      changed = true;
    }

    if (this.originalObject.CustomerDrawingFileId !== this.net.CustomerDrawingFileId) {
      netModification.CustomerDrawingFileId = this.net.CustomerDrawingFileId;
      changed = true;
    }

    if (changed) {
      try {
        netModification.Id = this.net.Id;
        await this.netService.put(netModification, netModification.Id);

        this.toastService.showSuccess('specialproduct.updated');
      } catch (error) {
        this.errorService.handleError(error);
      }
    }
  }

  setCertificateFileId(event: any) {
    this.net.CertificateFileId = event.detail.id;
  }

  removeCertificateFileId() {
    this.net.CertificateFileId = null;
  }

  cancel() {
    if (this.router.baseUrl.includes('special-product-detail')) {
      this.router.navigateToRoute('special-product-list');
    }
  }

  canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.net, this.originalObject) && !this.locked) {
      // tslint:disable-next-line:no-console
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              void this.updateSpecialProductDimension();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  onLengthChange({ target }: { target: HTMLInputElement }, type: 'favn' | 'meter' = 'meter') {
    if (type == 'meter') {
      this.net.SpecialProductDimension.LengthFavn = metersToFavn(target.value);
    } else if (type == 'favn') {
      this.net.SpecialProductDimension.Length = favnToMeters(target.value);
    }
  }

  onWidthChange({ target }: { target: HTMLInputElement }, type: 'favn' | 'meter' = 'meter') {
    if (type == 'meter') {
      this.net.SpecialProductDimension.WidthFavn = metersToFavn(target.value);
    } else if (type == 'favn') {
      this.net.SpecialProductDimension.Width = favnToMeters(target.value);
    }
  }
}
