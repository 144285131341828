import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CreateNetDto } from 'models/NetModels';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class SpecialProductNew {
  private specialProduct: CreateNetDto = new CreateNetDto();
  private originalObject: CreateNetDto = new CreateNetDto();
  private saving: boolean = false;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator,
    private utility: Utility,
    private dialogService: DialogService,
    private prompt: Prompt
  ) {    
    ValidationRules        
      .ensure('CustomerId').required().withMessage(i18n.tr('general.requiredField'))
      .ensure('NetIdentifier').required().withMessage(i18n.tr('general.requiredField'))
      .ensure('ProducerId').required().withMessage(i18n.tr('general.producerField'))
      .ensure('SpecialProductTypeId').required().withMessage(i18n.tr('general.requiredField'))
      .on(this.specialProduct);  
  }

  private activate(params) {
    if (params.CustomerId) {
      this.specialProduct.CustomerId = params.CustomerId;
      this.originalObject.CustomerId = params.CustomerId;
    }

    this.specialProduct.IsSpecialProduct = true;
  }

  private attached() {
    this.originalObject = JSON.parse(JSON.stringify(this.specialProduct));
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.specialProduct)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {          
          this.createSpecialProduct();          
        }
      });
  }

  private createSpecialProduct() {
    this.saving = true;
    
    this.netService
      .createNet(this.specialProduct)
      .then((res) => {
        this.originalObject = null;
        this.toastService.showSuccess('specialproduct.created');
        this.router.navigateToRoute('special-product-detail', { Id: res.Id });
        this.saving = false;
      })
      .catch((err) => {
        this.saving = false;
        this.errorService.handleError(err);
      });
  }

  private canDeactivate() {

    // NOTE: Will react even if you hade made no changes.
    // See issue #303.

    if (!this.originalObject) {
      return true;
    }

    // fix dates
    this.specialProduct = this.utility.areEqualDateFix(this.specialProduct, [{ key: 'ShipmentDate' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'ShipmentDate' }]);

    if (!this.utility.areEqual(this.specialProduct, this.originalObject, true)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel'  },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue'  }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

}
