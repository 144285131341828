import { SpecialProductTypeService } from './../../services/special-product-type-service';
import { HttpClient } from 'aurelia-fetch-client';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { CustomerService } from 'services/customer-service';
import { DepartmentService } from 'services/department-service';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ProducerService } from 'services/producer-service';
import { SiteService } from 'services/site-service';

@autoinject
export class SpecialProductFormBasic {

  @bindable private specialProduct: any;
  @bindable private customers: Array<Models.Customer>;
  @bindable private departments: Array<Models.Department>;
  @bindable private sites: Array<Models.Site>;

  private producers: Array<Models.Producer>;
  private specialProductTypes: Array<Models.SpecialProductType>;

  constructor(
    private customerService: CustomerService,
    private departmentService: DepartmentService,
    private errorService: ErrorService,
    private netService: NetService,
    private producerService: ProducerService,
    private specialProductTypeService: SpecialProductTypeService,
    private siteService: SiteService
  ) { }

  private attached() {
    this.customerService
      .getAll('?$orderby=Name')
      .then((res) => {
        this.customers = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private netChanged() {
    if (this.specialProduct && this.specialProduct.Id && this.specialProduct.CustomerId) {
      this.getDepartments(this.specialProduct.CustomerId);
      this.getSites(this.specialProduct.CustomerId);
    }
  }

  private getProducers(): Promise<Array<Models.Producer>> {
    return this.producerService.getAllCached()
      .then(res => {
        this.producers = res;
        return res;
      });
  }
  
  private getSpecialProductTypes(): Promise<Array<Models.SpecialProductType>> {
    return this.specialProductTypeService.getAllCached()
      .then(res => {
        this.specialProductTypes = res;
        return res;
      });
  }

  private getDepartments(CustomerId) {
    this.departmentService.getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.departments = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getSites(CustomerId) {
    this.siteService.getAll('?$filter=CustomerId eq ' + this.specialProduct.CustomerId)
      .then((res) => {
        this.sites = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private clearNetIdentifier(value) {
    this.specialProduct.CustomerId = null;
  }

  private setCustomer(value) {
    this.sites = [];
    this.departments = [];
    if (!value || value === undefined || value === '' || value === this.specialProduct.CustomerId) {
      return;
    } else {
      this.specialProduct.CustomerId = value;
      this.getDepartments(this.specialProduct.CustomerId);
    }
  }

  private setDepartment(evt) {

    if (evt.detail.value !== this.specialProduct.CustomerId) {
      this.sites = [];
      this.getSites(this.specialProduct.CustomerId);

    }
    this.specialProduct.DepartmentId = evt.detail.value;
  }

  private setSite(evt) {
    this.specialProduct.SiteId = evt.detail.value;
  }

  private setProducer(producerId) {
    this.specialProduct.ProducerId = producerId;
  }

  private setSpecialProductType(specialProductTypeId) {
    this.specialProduct.SpecialProductTypeId = specialProductTypeId;
  }
}
