import { AuthorizeStep } from 'authorizeStep';
import { HistoryDialog } from '../history-dialog/history-dialog';
import { DialogCloseResult, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PLATFORM } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ToastService } from 'services/toast-service';
import tippy from 'tippy.js';
import { StorageSelectionDialog } from 'components/dialogs/storage-selection/storage-selection-dialog';
import { StorageHistoryService } from 'services/storage-history-service';
import { ServiceStationService } from 'services/service-station-service';

@autoinject
export class SpecialProductDetailMain {
  private specialProduct: Models.Net;
  private subscription: Subscription;

  protected locked = false;
  protected userIsAdmin = false;
  protected storageLocation: any;

  constructor(
    private netService: NetService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private storageHistoryService: StorageHistoryService,
    private dialogService: DialogService,
    private serviceStationService: ServiceStationService
  ) {}

  private configureRouter(config: RouterConfiguration) {
    config.map([
      {
        route: ['', 'dimensions'],
        name: 'special-product-dimension',
        moduleId: PLATFORM.moduleName('./special-product-dimension'),
        tabindex: 0,
        entityShortName: 'net',
      },
      {
        route: ['info'],
        name: 'net-detail-info',
        moduleId: PLATFORM.moduleName('./../net/net-detail-services'),
        tabindex: 1,
        entityShortName: 'net',
      },
    ]);
  }

  private activate(params) {
    this.getSpecialProduct(params.Id);
    this.subscription = this.eventAggregator.subscribe('netLocked', (value) => {
      this.getSpecialProduct(params.Id);
    });

    if (!AuthorizeStep.auth.roles) {
      setTimeout(() => {
        if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Administrator') !== -1) {
          this.userIsAdmin = true;
        }
      }, 1000);
    } else {
      if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Administrator') !== -1) {
        this.userIsAdmin = true;
      }
    }

    void this.getStorageLocation(params.Id);
  }

  private detached() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private getSpecialProduct(id) {
    this.netService
      .getNetInfo(id)
      .then((res) => {
        this.specialProduct = res;
        this.locked = res.Locked ? res.Locked : false;

        setTimeout(() => {
          tippy('.tippy', {
            content: (reference) => {
              const title = reference.getAttribute('title');
              reference.removeAttribute('title');
              return title;
            },
          });
        });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private unlockNet() {
    this.netService
      .unlockNet(this.specialProduct.Id)
      .then((res) => {
        this.toastService.showSuccess('specialProduct.unlocked');
        window.location.reload();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private createService() {
    this.router.navigateToRoute('service-new', { NetId: this.specialProduct.Id });
  }

  private deleteNet() {
    return this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'dialog.deleteSpecialProductHeader',
          message: 'dialog.deleteSpecialProductText',
          actions: {
            delete: { enabled: true, t: 'dialog.deleteSpecialProduct' },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
            continue: { enabled: false },
          },
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'delete') {
            this.netService
              .delete(this.specialProduct.Id)
              .then((res) => {
                this.toastService.showSuccess('specialProduct.successfullyDeleted');
                this.router.navigateToRoute('special-product-list');
              })
              .catch((err) => this.errorService.handleError(err));
            return false;
          } else {
            return true;
          }
        }
      });
  }

  private async getStorageLocation(netId?: number) {
    const storage = await this.storageHistoryService.getNetStorage(this.specialProduct?.Id || netId);
    if (storage) {
      const station = await this.serviceStationService.get(storage.ServiceStationId);
      if (station) {
        this.storageLocation = {
          serviceStationId: station.Id,
          serviceStationName: station.Name,
          storageName: storage.Name,
          storageId: storage.Id,
        };
      }
    } else {
      this.storageLocation = undefined;
    }
  }

  protected editStorage() {
    this.dialogService
      .open({
        viewModel: StorageSelectionDialog,
        model: {
          netId: this.specialProduct?.Id,
          storageLocation: this.storageLocation,
        },
        lock: false,
        position: () => {
          //
        },
      })
      .whenClosed((response: DialogCloseResult) => {
        void this.getStorageLocation(this.specialProduct.Id);
        if (response.wasCancelled) return false;
        document.querySelector('html').style.overflowY = null;
      })
      .catch((err) => {
        document.querySelector('html').style.overflowY = null;
        this.errorService.handleError(err);
      });
  }

  private showHistory() {
    let viewModel = {
      EntityType: 'Net',
      EntityId: this.specialProduct.Id,
    };

    this.dialogService.open({ viewModel: HistoryDialog, model: viewModel, lock: false, position: (mc, mo) => {} });
  }
}
