import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Models } from 'models/core';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class SpecialProductNew {
  private specialProduct: Models.Net;

  constructor(
    private deleteDialogService: DeleteDialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator
  ) {}

  private activate(params) {
    this.netService
      .get(params.Id)
      .then((net) => {
        this.specialProduct = net;

        ValidationRules
          .ensure('CustomerId').required().withMessage(this.i18n.tr('general.requiredField'))
          .ensure('NetIdentifier').required().withMessage(this.i18n.tr('general.requiredField'))
          .ensure('ProducerId').required().withMessage(this.i18n.tr('general.producerField'))
          .ensure('SpecialProductTypeId').required().withMessage(this.i18n.tr('general.requiredField'))
          .on(this.specialProduct);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.specialProduct)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.updateNet();
        }
      });
  }

  private updateNet() {
    this.netService
      .put(this.specialProduct, this.specialProduct.Id)
      .then((res) => {
        this.eventAggregator.publish('netListReset', 1);
        this.toastService.showSuccess('specialproduct.updated');
        this.router.navigateToRoute('special-product-detail', { Id: this.specialProduct.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteSpecialProduct() {

    this.deleteDialogService.confirmBeforeDelete(
      () => {
      this.netService
        .deleteNet(this.specialProduct.Id)
        .then((res) => {
          this.toastService.showSuccess('specialproduct.deleted');
          this.router.navigateToRoute('special-product-list');
        })
        .catch((err) => this.errorService.handleError(err));
      }
    );
  }

  private gotoParentView() {
    this.eventAggregator.publish('netListReset', 1);
    this.router.navigateToRoute('special-product-list');
  }
}
